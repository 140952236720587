/**
 * Created by gantushig on 1/30/16.
 */

import keyMirror from 'keymirror';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    console.log("dev");
} else {
    // production code
    console.log("prod");
}

//PROD
export const BASE_URL= 'https://api.gs1mongolia.org/';
export const BASE_EXPRESS_URL= 'https://api2.gs1mongolia.org/';


//DEV

//export const BASE_EXPRESS_URL= 'http://localhost:3000/';
//export const BASE_URL= 'http://192.168.1.5:8090/';
//export const BASE_URL= 'http://172.20.10.2:8090/';

//export const BASE_EXPRESS_URL = process.env.REACT_APP_BASE_EXPRESS_URL;
//export const BASE_URL= process.env.REACT_APP_BASE_URL;

//export const BASE_URL= 'http://172.20.10.2:8090/';
//export const BASE_URL= 'http://192.168.1.100:8090/';
//export const BASE_URL= 'http://192.168.1.101:8090/';
//export const BASE_URL= 'http://192.168.1.102:8090/';
//export const BASE_URL= 'http://192.168.0.102:8090/';
//export const BASE_URL= 'http://192.168.0.105:8090/';
//export const BASE_URL= 'http://192.168.1.101:8090/';

//export const BASE_URL= 'http://192.168.1.4:8090/';
//export const BASE_URL= 'http://192.168.1.2:8090/';
//export const BASE_URL= 'http://192.168.1.121:8090/';
//export const BASE_URL= 'http://192.168.0.106:8090/';
//export const BASE_URL= 'http://192.168.0.100:8090/';
//export const BASE_URL= 'http://192.168.0.111:8090/';
//export const BASE_URL= 'http://192.168.8.2:8090/';
//export const BASE_URL= 'http://192.168.8.3:8090/';
//export const BASE_URL= 'http://192.168.8.4:8090/';
//export const BASE_URL= 'http://192.168.8.5:8090/';



//export const BASE_URL= 'http://192.168.8.5:8090/';
//export const BASE_URL= 'http://172.20.10.2:8090/';
//export const BASE_URL= 'http://172.20.10.3:8090/';



export const ActionTypes =  keyMirror({
    INITIALIZE:null,
    ADD_ITEM: null,
    REMOVE_ITEM: null,
    INCREASE_ITEM:null,
    DECREASE_ITEM:null,
    RECEIVE_RAW_USERS:null,
    RECEIVE_INTERESTS:null,
    RECEIVE_FEED:null,
    CLICK_MENU:null,
    RECEIVE_MENU:null,
    POST_CREATED:null,

    TEMP_PRODUCT_CHANGE:null,

    LOGIN:null,
    LOGIN_SUCCESS:null,
    LOGIN_FAILURE:null,
    VERIFY_FAILURE:null,
    LOGOUT:null,

    CHECK_LOGIN_SUCCESS:null,
    CHECK_LOGIN_FAILURE:null,


    LOGIN_EXPRESS:null,
    LOGIN_EXPRESS_SUCCESS:null,
    LOGIN_EXPRESS_FAILURE:null,

    VERIFY_TOKEN:null,
    REGISTER:null,
    REGISTER_SUCCESS:null,
    REGISTER_FAILURE:null,

    START_RTM:null,
    START_RTM_SUCCESS:null,
    START_RTM_FAILURE:null,

    LOAD_FEES_REQUEST:null,
    LOAD_FEES_SUCCESS:null,
    LOAD_FEES_FAILURE:null,

    LOAD_PACKAGING_UNITS_REQUEST:null,
    LOAD_PACKAGING_UNITS_SUCCESS:null,
    LOAD_PACKAGING_UNITS_FAILURE:null,

    UPDATE_FEE_REQUEST:null,
    UPDATE_FEE_SUCCESS:null,
    UPDATE_FEE_FAILURE:null,

    LOAD_SECTORS_REQUEST:null,
    LOAD_SECTORS_SUCCESS:null,
    LOAD_SECTORS_FAILURE:null,

    LOAD_SECTORS_ALL_REQUEST:null,
    LOAD_SECTORS_ALL_SUCCESS:null,
    LOAD_SECTORS_ALL_FAILURE:null,

    LOAD_SECTORS_ALL_NO_AUTH_REQUEST:null,
    LOAD_SECTORS_ALL_NO_AUTH_SUCCESS:null,
    LOAD_SECTORS_ALL_NO_AUTH_FAILURE:null,

    LOAD_SECTORS_ALL_NO_AUTH_2_REQUEST:null,
    LOAD_SECTORS_ALL_NO_AUTH_2_SUCCESS:null,
    LOAD_SECTORS_ALL_NO_AUTH_2_FAILURE:null,

    CREATE_SECTOR_REQUEST:null,
    CREATE_SECTOR_SUCCESS:null,
    CREATE_SECTOR_FAILURE:null,

    UPDATE_SECTOR_REQUEST:null,
    UPDATE_SECTOR_SUCCESS:null,
    UPDATE_SECTOR_FAILURE:null,

    DELETE_SECTOR_REQUEST:null,
    DELETE_SECTOR_SUCCESS:null,
    DELETE_SECTOR_FAILURE:null,

    LOAD_CLASSIF_NEW_MAIN_REQUEST:null,
    LOAD_CLASSIF_NEW_MAIN_SUCCESS:null,
    LOAD_CLASSIF_NEW_MAIN_FAILURE:null,

    LOAD_CLASSIF_NEW_SUB_REQUEST:null,
    LOAD_CLASSIF_NEW_SUB_SUCCESS:null,
    LOAD_CLASSIF_NEW_SUB_FAILURE:null,

    LOAD_CLASSIF_NEW_UNIT_REQUEST:null,
    LOAD_CLASSIF_NEW_UNIT_SUCCESS:null,
    LOAD_CLASSIF_NEW_UNIT_FAILURE:null,

    PRINT_CLASSIFS_REQUEST:null,
    PRINT_CLASSIFS_SUCCESS:null,
    PRINT_CLASSIFS_FAILURE:null,

    LOAD_CLASSIF_NOUV_MAIN_REQUEST:null,
    LOAD_CLASSIF_NOUV_MAIN_SUCCESS:null,
    LOAD_CLASSIF_NOUV_MAIN_FAILURE:null,

    LOAD_CLASSIF_NOUV_SUB_REQUEST:null,
    LOAD_CLASSIF_NOUV_SUB_SUCCESS:null,
    LOAD_CLASSIF_NOUV_SUB_FAILURE:null,

    LOAD_CLASSIF_NOUV_UNIT_REQUEST:null,
    LOAD_CLASSIF_NOUV_UNIT_SUCCESS:null,
    LOAD_CLASSIF_NOUV_UNIT_FAILURE:null,

    LOAD_CLASSIF_MAIN_REQUEST:null,
    LOAD_CLASSIF_MAIN_SUCCESS:null,
    LOAD_CLASSIF_MAIN_FAILURE:null,

    CREATE_CLASSIF_MAIN_REQUEST:null,
    CREATE_CLASSIF_MAIN_SUCCESS:null,
    CREATE_CLASSIF_MAIN_FAILURE:null,

    UPDATE_CLASSIF_MAIN_REQUEST:null,
    UPDATE_CLASSIF_MAIN_SUCCESS:null,
    UPDATE_CLASSIF_MAIN_FAILURE:null,

    DELETE_CLASSIF_MAIN_REQUEST:null,
    DELETE_CLASSIF_MAIN_SUCCESS:null,
    DELETE_CLASSIF_MAIN_FAILURE:null,

    LOAD_CLASSIF_SUB_REQUEST:null,
    LOAD_CLASSIF_SUB_SUCCESS:null,
    LOAD_CLASSIF_SUB_FAILURE:null,

    CREATE_CLASSIF_SUB_REQUEST:null,
    CREATE_CLASSIF_SUB_SUCCESS:null,
    CREATE_CLASSIF_SUB_FAILURE:null,

    UPDATE_CLASSIF_SUB_REQUEST:null,
    UPDATE_CLASSIF_SUB_SUCCESS:null,
    UPDATE_CLASSIF_SUB_FAILURE:null,

    DELETE_CLASSIF_SUB_REQUEST:null,
    DELETE_CLASSIF_SUB_SUCCESS:null,
    DELETE_CLASSIF_SUB_FAILURE:null,


    LOAD_CLASSIF_UNIT_REQUEST:null,
    LOAD_CLASSIF_UNIT_SUCCESS:null,
    LOAD_CLASSIF_UNIT_FAILURE:null,

    CREATE_CLASSIF_UNIT_REQUEST:null,
    CREATE_CLASSIF_UNIT_SUCCESS:null,
    CREATE_CLASSIF_UNIT_FAILURE:null,

    UPDATE_CLASSIF_UNIT_REQUEST:null,
    UPDATE_CLASSIF_UNIT_SUCCESS:null,
    UPDATE_CLASSIF_UNIT_FAILURE:null,

    DELETE_CLASSIF_UNIT_REQUEST:null,
    DELETE_CLASSIF_UNIT_SUCCESS:null,
    DELETE_CLASSIF_UNIT_FAILURE:null,

    RESET_COMPANIES_SEARCH:null,
    LOAD_COMPANIES_REQUEST:null,
    LOAD_COMPANIES_SUCCESS:null,
    LOAD_COMPANIES_FAILURE:null,

    LOAD_COMPANIES_SET_ORDER:null,
    LOAD_INVOICES_SET_ORDER:null,


    FETCH_CONTACTS_REQUEST:null,
    FETCH_CONTACTS_SUCCESS:null,
    FETCH_CONTACTS_FAILURE:null,

    FETCH_CONTACT_REQUEST:null,
    FETCH_CONTACT_SUCCESS:null,
    FETCH_CONTACT_FAILURE:null,
    // RESET_GCPS_SEARCH:null,
    // LOAD_GCPS_SET_ORDER:null,

    FETCH_GCP_REQUEST:null,
    FETCH_GCP_SUCCESS:null,
    FETCH_GCP_FAILURE:null,

    FETCH_GCPS_REQUEST:null,
    FETCH_GCPS_SUCCESS:null,
    FETCH_GCPS_FAILURE:null,

    FETCH_NOTES_REQUEST:null,
    FETCH_NOTES_SUCCESS:null,
    FETCH_NOTES_FAILURE:null,

    COMPANIES_PAGE_SELECTED:null,
    INVOICES_PAGE_SELECTED:null,

    RESET_GCPS_SEARCH:null,
    LOAD_GCPS_REQUEST:null,
    LOAD_GCPS_SUCCESS:null,
    LOAD_GCPS_FAILURE:null,


    LOAD_GCPS_SET_ORDER:null,
    GCPS_PAGE_SELECTED:null,

    LOAD_HQ_GCPS_REQUEST:null,
    LOAD_HQ_GCPS_SUCCESS:null,
    LOAD_HQ_GCPS_FAILURE:null,

    LOAD_HQ_PRODUCTS_REQUEST:null,
    LOAD_HQ_PRODUCTS_SUCCESS:null,
    LOAD_HQ_PRODUCTS_FAILURE:null,

    LOAD_HQ_BATCH_REQUEST:null,
    LOAD_HQ_BATCH_SUCCESS:null,
    LOAD_HQ_BATCH_FAILURE:null,

    LOAD_HQ_BATCHES_REQUEST:null,
    LOAD_HQ_BATCHES_SUCCESS:null,
    LOAD_HQ_BATCHES_FAILURE:null,

    GET_VERIFIED_GTIN_COUNT_REQUEST:null,
    GET_VERIFIED_GTIN_COUNT_SUCCESS:null,
    GET_VERIFIED_GTIN_COUNT_FAILURE:null,

    GET_VERIFIED_GCP_COUNT_REQUEST:null,
    GET_VERIFIED_GCP_COUNT_SUCCESS:null,
    GET_VERIFIED_GCP_COUNT_FAILURE:null,

    GET_VERIFIED_GCP_LIST_REQUEST:null,
    GET_VERIFIED_GCP_LIST_SUCCESS:null,
    GET_VERIFIED_GCP_LIST_FAILURE:null,

    SEND_VERIFIED_GTINS_REQUEST:null,
    SEND_VERIFIED_GTINS_SUCCESS:null,
    SEND_VERIFIED_GTINS_FAILURE:null,

    SEND_VERIFIED_GCPS_REQUEST:null,
    SEND_VERIFIED_GCPS_SUCCESS:null,
    SEND_VERIFIED_GCPS_FAILURE:null,

    LOAD_COUNTRIES_REQUEST:null,
    LOAD_COUNTRIES_SUCCESS:null,
    LOAD_COUNTRIES_FAILURE:null,

    LOAD_BARCODES_REQUEST:null,
    LOAD_BARCODES_SUCCESS:null,
    LOAD_BARCODES_FAILURE:null,

    LOAD_PRODUCT8_FILES_REQUEST:null,
    LOAD_PRODUCT8_FILES_SUCCESS:null,
    LOAD_PRODUCT8_FILES_FAILURE:null,

    UPLOAD_BARCODE_REQUEST:null,
    UPLOAD_BARCODE_SUCCESS:null,
    UPLOAD_BARCODE_FAILURE:null,

    UPLOAD_PRODUCT8_FILE_REQUEST:null,
    UPLOAD_PRODUCT8_FILE_SUCCESS:null,
    UPLOAD_PRODUCT8_FILE_FAILURE:null,

    UPDATE_BARCODE_REQUEST:null,
    UPDATE_BARCODE_SUCCESS:null,
    UPDATE_BARCODE_FAILURE:null,


    DELETE_BARCODE_REQUEST:null,
    DELETE_BARCODE_SUCCESS:null,
    DELETE_BARCODE_FAILURE:null,

    LOAD_ALL_INVOICES_REQUEST:null,
    LOAD_ALL_INVOICES_SUCCESS:null,
    LOAD_ALL_INVOICES_FAILURE:null,

    LOAD_INVOICE_MAILS_REQUEST:null,
    LOAD_INVOICE_MAILS_SUCCESS:null,
    LOAD_INVOICE_MAILS_FAILURE:null,

    LOAD_INVOICE_MAIL_ITEMS_REQUEST:null,
    LOAD_INVOICE_MAIL_ITEMS_SUCCESS:null,
    LOAD_INVOICE_MAIL_ITEMS_FAILURE:null,

    LOAD_INVOICE_MAIL_TEMPLATE_LIST_REQUEST:null,
    LOAD_INVOICE_MAIL_TEMPLATE_LIST_SUCCESS:null,
    LOAD_INVOICE_MAIL_TEMPLATE_LIST_FAILURE:null,

    LOAD_INVOICE_MAIL_TEMPLATE_LINK_REQUEST:null,
    LOAD_INVOICE_MAIL_TEMPLATE_LINK_SUCCESS:null,
    LOAD_INVOICE_MAIL_TEMPLATE_LINK_FAILURE:null,


    LOAD_INVOICE_MAIL_TEMPLATE_REQUEST:null,
    LOAD_INVOICE_MAIL_TEMPLATE_SUCCESS:null,
    LOAD_INVOICE_MAIL_TEMPLATE_FAILURE:null,

    LOAD_SMS_TEMPLATE_REQUEST:null,
    LOAD_SMS_TEMPLATE_SUCCESS:null,
    LOAD_SMS_TEMPLATE_FAILURE:null,

    UPDATE_SMS_TEMPLATE_REQUEST:null,
    UPDATE_SMS_TEMPLATE_SUCCESS:null,
    UPDATE_SMS_TEMPLATE_FAILURE:null,

    SEND_SMS_REQUEST:null,
    SEND_SMS_SUCCESS:null,
    SEND_SMS_FAILURE:null,

    CANCEL_SMS_REQUEST:null,
    CANCEL_SMS_SUCCESS:null,
    CANCEL_SMS_FAILURE:null,

    CREATE_INVOICE_BULK_REQUEST:null,
    CREATE_INVOICE_BULK_SUCCESS:null,
    CREATE_INVOICE_BULK_FAILURE:null,

    DELETE_INVOICES_BULK_REQUEST:null,
    DELETE_INVOICES_BULK_SUCCESS:null,
    DELETE_INVOICES_BULK_FAILURE:null,

    DELETE_ALL_INVOICES_BULK_REQUEST:null,
    DELETE_ALL_INVOICES_BULK_SUCCESS:null,
    DELETE_ALL_INVOICES_BULK_FAILURE:null,

    PRINT_INVOICES_BULK_REQUEST:null,
    PRINT_INVOICES_BULK_SUCCESS:null,
    PRINT_INVOICES_BULK_FAILURE:null,

    PRINT_ALL_INVOICES_BULK_REQUEST:null,
    PRINT_ALL_INVOICES_BULK_SUCCESS:null,
    PRINT_ALL_INVOICES_BULK_FAILURE:null,

    LOAD_INVOICES_REQUEST:null,
    LOAD_INVOICES_SUCCESS:null,
    LOAD_INVOICES_FAILURE:null,

    GET_INVOICE_REQUEST:null,
    GET_INVOICE_SUCCESS:null,
    GET_INVOICE_FAILURE:null,

    CREATE_INVOICE_REQUEST:null,
    CREATE_INVOICE_SUCCESS:null,
    CREATE_INVOICE_FAILURE:null,

    PRINT_INVOICE_REQUEST:null,
    PRINT_INVOICE_SUCCESS:null,
    PRINT_INVOICE_FAILURE:null,

    DELETE_INVOICE_REQUEST:null,
    DELETE_INVOICE_SUCCESS:null,
    DELETE_INVOICE_FAILURE:null,

    LOAD_PAYMENTS_REQUEST:null,
    LOAD_PAYMENTS_SUCCESS:null,
    LOAD_PAYMENTS_FAILURE:null,

    CREATE_PAYMENT_REQUEST:null,
    CREATE_PAYMENT_SUCCESS:null,
    CREATE_PAYMENT_FAILURE:null,

    DELETE_PAYMENT_REQUEST:null,
    DELETE_PAYMENT_SUCCESS:null,
    DELETE_PAYMENT_FAILURE:null,

    GET_DASHBOARD_REQUEST:null,
    GET_DASHBOARD_SUCCESS:null,
    GET_DASHBOARD_FAILURE:null,

    FETCH_AIMAKS_REQUEST:null,
    FETCH_AIMAKS_SUCCESS:null,
    FETCH_AIMAKS_FAILURE:null,

    SET_UI_TITLE:null,
    SET_GCP_PREFIX:null,

    GET_COMPANY_REQUEST:null,
    GET_COMPANY_SUCCESS:null,
    GET_COMPANY_FAILURE:null,

    UPLOAD_COMPANY_DOC_REQUEST:null,
    UPLOAD_COMPANY_DOC_SUCCESS:null,
    UPLOAD_COMPANY_DOC_FAILURE:null,

    DOWNLOAD_COMPANY_DOC_REQUEST:null,
    DOWNLOAD_COMPANY_DOC_SUCCESS:null,
    DOWNLOAD_COMPANY_DOC_FAILURE:null,

    DOWNLOAD_COMPANY_APPL_DOC_REQUEST:null,
    DOWNLOAD_COMPANY_APPL_DOC_SUCCESS:null,
    DOWNLOAD_COMPANY_APPL_DOC_FAILURE:null,

    DELETE_COMPANY_DOC_REQUEST:null,
    DELETE_COMPANY_DOC_SUCCESS:null,
    DELETE_COMPANY_DOC_FAILURE:null,

    CREATE_COMPANY_REQUEST:null,
    CREATE_COMPANY_SUCCESS:null,
    CREATE_COMPANY_FAILURE:null,

    CREATE_COMPANY_SELF_REQUEST:null,
    CREATE_COMPANY_SELF_SUCCESS:null,
    CREATE_COMPANY_SELF_FAILURE:null,

    DOWNLOAD_BARCODE_REQUEST:null,
    DOWNLOAD_BARCODE_SUCCESS:null,
    DOWNLOAD_BARCODE_FAILURE:null,

    // CHANGE_PRODUCT_CLASSIF_REQUEST:null,
    // CHANGE_PRODUCT_CLASSIF_SUCCESS:null,
    // CHANGE_PRODUCT_CLASSIF_FAILURE:null,

    START_REQUEST:null,
    FINISH_REQUEST:null,
    SUCCESS:null,
    FAILURE:null,

    UPDATE_COMPANY_REQUEST:null,
    UPDATE_COMPANY_SUCCESS:null,
    UPDATE_COMPANY_FAILURE:null,

    CHANGE_COMPANY_REQUEST:null,
    CHANGE_COMPANY_SUCCESS:null,
    CHANGE_COMPANY_FAILURE:null,

    DELETE_COMPANY_REQUEST:null,
    DELETE_COMPANY_SUCCESS:null,
    DELETE_COMPANY_FAILURE:null,

    CREATE_CONTACT_REQUEST:null,
    CREATE_CONTACT_SUCCESS:null,
    CREATE_CONTACT_FAILURE:null,

    UPDATE_CONTACT_REQUEST:null,
    UPDATE_CONTACT_SUCCESS:null,
    UPDATE_CONTACT_FAILURE:null,

    DELETE_CONTACT_REQUEST:null,
    DELETE_CONTACT_SUCCESS:null,
    DELETE_CONTACT_FAILURE:null,

    CREATE_ADMIN_USER_REQUEST:null,
    CREATE_ADMIN_USER_SUCCESS:null,
    CREATE_ADMIN_USER_FAILURE:null,

    UPDATE_ADMIN_USER_PASSWORD_REQUEST:null,
    UPDATE_ADMIN_USER_PASSWORD_SUCCESS:null,
    UPDATE_ADMIN_USER_PASSWORD_FAILURE:null,

    CREATE_NOTE_REQUEST:null,
    CREATE_NOTE_SUCCESS:null,
    CREATE_NOTE_FAILURE:null,

    DELETE_NOTE_REQUEST:null,
    DELETE_NOTE_SUCCESS:null,
    DELETE_NOTE_FAILURE:null,

    CREATE_PAYMENT_NOTE_REQUEST:null,
    CREATE_PAYMENT_NOTE_SUCCESS:null,
    CREATE_PAYMENT_NOTE_FAILURE:null,

    DELETE_PAYMENT_NOTE_REQUEST:null,
    DELETE_PAYMENT_NOTE_SUCCESS:null,
    DELETE_PAYMENT_NOTE_FAILURE:null,

    CREATE_GCP_REQUEST:null,
    CREATE_GCP_SUCCESS:null,
    CREATE_GCP_FAILURE:null,

    UPDATE_GCP_REQUEST:null,
    UPDATE_GCP_SUCCESS:null,
    UPDATE_GCP_FAILURE:null,

    UPDATE_INVOICE_MAIL_TEMPLATE_LINK_REQUEST:null,
    UPDATE_INVOICE_MAIL_TEMPLATE_LINK_SUCCESS:null,
    UPDATE_INVOICE_MAIL_TEMPLATE_LINK_FAILURE:null,

    DELETE_GCP_REQUEST:null,
    DELETE_GCP_SUCCESS:null,
    DELETE_GCP_FAILURE:null,

    VERIFY_GCP_REQUEST:null,
    VERIFY_GCP_SUCCESS:null,
    VERIFY_GCP_FAILURE:null,

    RESET_PRODUCT_UPDATE_STATUS:null,
    SELECT_PRODUCT:null,
    SELECT_PRODUCT_HEADER:null,

    LOAD_ALL_PRODUCTS_REQUEST:null,
    LOAD_ALL_PRODUCTS_SUCCESS:null,
    LOAD_ALL_PRODUCTS_FAILURE:null,

    LOAD_ALL_PRODUCTS14_REQUEST:null,
    LOAD_ALL_PRODUCTS14_SUCCESS:null,
    LOAD_ALL_PRODUCTS14_FAILURE:null,

    LOAD_EDIT_PRODUCTS_REQUEST:null,
    LOAD_EDIT_PRODUCTS_SUCCESS:null,
    LOAD_EDIT_PRODUCTS_FAILURE:null,

    LOAD_VERIFY_PRODUCTS_REQUEST:null,
    LOAD_VERIFY_PRODUCTS_SUCCESS:null,
    LOAD_VERIFY_PRODUCTS_FAILURE:null,

    LOAD_ALL_PRODUCT8S_REQUEST:null,
    LOAD_ALL_PRODUCT8S_SUCCESS:null,
    LOAD_ALL_PRODUCT8S_FAILURE:null,

    PRINT_ALL_PRODUCTS_REQUEST:null,
    PRINT_ALL_PRODUCTS_SUCCESS:null,
    PRINT_ALL_PRODUCTS_FAILURE:null,

    UPDATE_PRODUCT_MULTI_REQUEST:null,
    UPDATE_PRODUCT_MULTI_SUCCESS:null,
    UPDATE_PRODUCT_MULTI_FAILURE:null,

    DELETE_HQ_PRODUCTS_REQUEST:null,
    DELETE_HQ_PRODUCTS_SUCCESS:null,
    DELETE_HQ_PRODUCTS_FAILURE:null,

    DELETE_HQ_GCPS_REQUEST:null,
    DELETE_HQ_GCPS_SUCCESS:null,
    DELETE_HQ_GCPS_FAILURE:null,

    PRINT_COMPANIES_REQUEST:null,
    PRINT_COMPANIES_SUCCESS:null,
    PRINT_COMPANIES_FAILURE:null,

    LOAD_PRODUCTS_REQUEST:null,
    LOAD_PRODUCTS_SUCCESS:null,
    LOAD_PRODUCTS_FAILURE:null,

    LOAD_COMPANY_PRODUCT8S_REQUEST:null,
    LOAD_COMPANY_PRODUCT8S_SUCCESS:null,
    LOAD_COMPANY_PRODUCT8S_FAILURE:null,

    LOAD_PRODUCTS_ORDER_CHANGED:null,
    LOAD_PRODUCTS_PAGE_CHANGED:null,
    LOAD_PRODUCTS_RESET_PAGE_ORDER:null,
    LOAD_PRODUCTS_ALL_SEARCH:null,
    LOAD_PRODUCTS_ALL_PRINT:null,

    LOAD_PRODUCT8S_ALL_SEARCH:null,

    INIT_TEMP_PRODUCT:null,

    GET_PRODUCT_REQUEST:null,
    GET_PRODUCT_SUCCESS:null,
    GET_PRODUCT_FAILURE:null,

    GET_PRODUCT8_REQUEST:null,
    GET_PRODUCT8_SUCCESS:null,
    GET_PRODUCT8_FAILURE:null,

    GET_PRODUCT_IMAGE_REQUEST:null,
    GET_PRODUCT_IMAGE_SUCCESS:null,
    GET_PRODUCT_IMAGE_FAILURE:null,

    GET_PRODUCT_IMAGE_APPL_REQUEST:null,
    GET_PRODUCT_IMAGE_APPL_SUCCESS:null,
    GET_PRODUCT_IMAGE_APPL_FAILURE:null,

    UPLOAD_PRODUCT_IMAGE_REQUEST:null,
    UPLOAD_PRODUCT_IMAGE_SUCCESS:null,
    UPLOAD_PRODUCT_IMAGE_FAILURE:null,


    DELETE_PRODUCT_IMAGE_REQUEST:null,
    DELETE_PRODUCT_IMAGE_SUCCESS:null,
    DELETE_PRODUCT_IMAGE_FAILURE:null,


    CREATE_USER_REQUEST:null,
    CREATE_USER_SUCCESS:null,
    CREATE_USER_FAILURE:null,

    UPDATE_USER_REQUEST:null,
    UPDATE_USER_SUCCESS:null,
    UPDATE_USER_FAILURE:null,

    CREATE_PRODUCT_REQUEST:null,
    CREATE_PRODUCT_SUCCESS:null,
    CREATE_PRODUCT_FAILURE:null,

    CREATE_PRODUCT8_REQUEST:null,
    CREATE_PRODUCT8_SUCCESS:null,
    CREATE_PRODUCT8_FAILURE:null,

    UPDATE_PRODUCT_REQUEST:null,
    UPDATE_PRODUCT_SUCCESS:null,
    UPDATE_PRODUCT_FAILURE:null,

    VERIFY_PRODUCT_REQUEST:null,
    VERIFY_PRODUCT_SUCCESS:null,
    VERIFY_PRODUCT_FAILURE:null,

    HQ_PRODUCT_REQUEST:null,
    HQ_PRODUCT_SUCCESS:null,
    HQ_PRODUCT_FAILURE:null,

    UPDATE_PRODUCT8_REQUEST:null,
    UPDATE_PRODUCT8_SUCCESS:null,
    UPDATE_PRODUCT8_FAILURE:null,

    UPDATE_PRODUCTS_CAT_REQUEST:null,
    UPDATE_PRODUCTS_CAT_SUCCESS:null,
    UPDATE_PRODUCTS_CAT_FAILURE:null,

    CREATE_INVOICE_MAIL_TEMPLATE_REQUEST:null,
    CREATE_INVOICE_MAIL_TEMPLATE_SUCCESS:null,
    CREATE_INVOICE_MAIL_TEMPLATE_FAILURE:null,

    UPDATE_INVOICE_MAIL_TEMPLATE_REQUEST:null,
    UPDATE_INVOICE_MAIL_TEMPLATE_SUCCESS:null,
    UPDATE_INVOICE_MAIL_TEMPLATE_FAILURE:null,

    REMOVE_INVOICE_MAIL_TEMPLATE_REQUEST:null,
    REMOVE_INVOICE_MAIL_TEMPLATE_SUCCESS:null,
    REMOVE_INVOICE_MAIL_TEMPLATE_FAILURE:null,

    SEND_INVOICE_MAILS_REQUEST:null,
    SEND_INVOICE_MAILS_SUCCESS:null,
    SEND_INVOICE_MAILS_FAILURE:null,

    CREATE_INVOICE_MAIL_REQUEST:null,
    CREATE_INVOICE_MAIL_SUCCESS:null,
    CREATE_INVOICE_MAIL_FAILURE:null,

    // UPDATE_PRODUCT_REQUEST_CAT_REQUEST:null,
    // UPDATE_PRODUCT_REQUEST_CAT_SUCCESS:null,
    // UPDATE_PRODUCT_REQUEST_CAT_FAILURE:null,

    DELETE_PRODUCT_REQUEST:null,
    DELETE_PRODUCT_SUCCESS:null,
    DELETE_PRODUCT_FAILURE:null,


    LOAD_COMPANY_APPLICATIONS_REQUEST:null,
    LOAD_COMPANY_APPLICATIONS_SUCCESS:null,
    LOAD_COMPANY_APPLICATIONS_FAILURE:null,

    GET_COMPANY_APPLICATION_REQUEST:null,
    GET_COMPANY_APPLICATION_SUCCESS:null,
    GET_COMPANY_APPLICATION_FAILURE:null,

    ACTIVATE_COMPANY_APPLICATION_REQUEST:null,
    ACTIVATE_COMPANY_APPLICATION_SUCCESS:null,
    ACTIVATE_COMPANY_APPLICATION_FAILURE:null,

    UPDATE_COMPANY_APPLICATION_REQUEST:null,
    UPDATE_COMPANY_APPLICATION_SUCCESS:null,
    UPDATE_COMPANY_APPLICATION_FAILURE:null,

    CHANGE_COMPANY_APPLICATION_REQUEST:null,
    CHANGE_COMPANY_APPLICATION_SUCCESS:null,
    CHANGE_COMPANY_APPLICATION_FAILURE:null,

    DELETE_COMPANY_APPLICATION_REQUEST:null,
    DELETE_COMPANY_APPLICATION_SUCCESS:null,
    DELETE_COMPANY_APPLICATION_FAILURE:null,


    LOAD_PRODUCT_APPLICATIONS_REQUEST:null,
    LOAD_PRODUCT_APPLICATIONS_SUCCESS:null,
    LOAD_PRODUCT_APPLICATIONS_FAILURE:null,

    LOAD_PRODUCT_IMAGE_APPLICATIONS_REQUEST:null,
    LOAD_PRODUCT_IMAGE_APPLICATIONS_SUCCESS:null,
    LOAD_PRODUCT_IMAGE_APPLICATIONS_FAILURE:null,

    GET_PRODUCT_APPLICATION_REQUEST:null,
    GET_PRODUCT_APPLICATION_SUCCESS:null,
    GET_PRODUCT_APPLICATION_FAILURE:null,

    CREATE_PRODUCT_APPLICATION_REQUEST:null,
    CREATE_PRODUCT_APPLICATION_SUCCESS:null,
    CREATE_PRODUCT_APPLICATION_FAILURE:null,

//    COPY_PRODUCT_APPLICATION_CATS:null,

    ACTIVATE_PRODUCT_APPLICATION_REQUEST:null,
    ACTIVATE_PRODUCT_APPLICATION_SUCCESS:null,
    ACTIVATE_PRODUCT_APPLICATION_FAILURE:null,

    UPDATE_PRODUCT_APPLICATION_REQUEST:null,
    UPDATE_PRODUCT_APPLICATION_SUCCESS:null,
    UPDATE_PRODUCT_APPLICATION_FAILURE:null,

    CHANGE_PRODUCT_APPLICATION_REQUEST:null,
    CHANGE_PRODUCT_APPLICATION_SUCCESS:null,
    CHANGE_PRODUCT_APPLICATION_FAILURE:null,

    DENY_PRODUCT_APPLICATION_REQUEST:null,
    DENY_PRODUCT_APPLICATION_SUCCESS:null,
    DENY_PRODUCT_APPLICATION_FAILURE:null,

    DELETE_PRODUCT_APPLICATION_REQUEST:null,
    DELETE_PRODUCT_APPLICATION_SUCCESS:null,
    DELETE_PRODUCT_APPLICATION_FAILURE:null,

    ACTIVATE_PRODUCT_IMAGE_APPLICATION_REQUEST:null,
    ACTIVATE_PRODUCT_IMAGE_APPLICATION_SUCCESS:null,
    ACTIVATE_PRODUCT_IMAGE_APPLICATION_FAILURE:null,

    DENY_PRODUCT_IMAGE_APPLICATION_REQUEST:null,
    DENY_PRODUCT_IMAGE__APPLICATION_SUCCESS:null,
    DENY_PRODUCT_IMAGE__APPLICATION_FAILURE:null,


    FORGOT_PASSWORD_SENT:null,


    LOGOUT_REQUEST:null,
    LOGOUT_SUCCESS:null,
    LOGOUT_FAILURE:null,

    LOAD_ME_REQUEST:null,
    LOAD_ME_SUCCESS:null,
    LOAD_ME_FAILURE:null,

    ADD_ORG_REQUEST:null,
    ADD_ORG_SUCCESS:null,
    ADD_ORG_FAILURE:null,

    LOAD_APPTS_REQUEST:null,
    LOAD_APPTS_SUCCESS:null,
    LOAD_APPTS_FAILURE:null,

    GET_APPT_REQUEST:null,
    GET_APPT_SUCCESS:null,
    GET_APPT_FAILURE:null,

    LOAD_WMS_REQUEST:null,
    LOAD_WMS_SUCCESS:null,
    LOAD_WMS_FAILURE:null,

    LOAD_READINGS_REQUEST:null,
    LOAD_READINGS_SUCCESS:null,
    LOAD_READINGS_FAILURE:null,

    LOAD_READINGS_NEW_REQUEST:null,
    LOAD_READINGS_NEW_SUCCESS:null,
    LOAD_READINGS_NEW_FAILURE:null,

    LOAD_READINGS_OWN_REQUEST:null,
    LOAD_READINGS_OWN_SUCCESS:null,
    LOAD_READINGS_OWN_FAILURE:null,

    LOAD_ONE_READING_REQUEST:null,
    LOAD_ONE_READING_SUCCESS:null,
    LOAD_ONE_READING_FAILURE:null,

    LOAD_INVOICES_OWN_REQUEST:null,
    LOAD_INVOICES_OWN_SUCCESS:null,
    LOAD_INVOICES_OWN_FAILURE:null,

    LOAD_DOCUMENTS_REQUEST:null,
    LOAD_DOCUMENTS_SUCCESS:null,
    LOAD_DOCUMENTS_FAILURE:null,

    TOGGLE_DRAWER:null,

    UPLOAD_XLS_REQUEST:null,
    UPLOAD_XLS_SUCCESS:null,
    UPLOAD_XLS_FAILURE:null,

    FETCH_SMS_ITEM_REQUEST:null,
    FETCH_SMS_ITEM_SUCCESS:null,
    FETCH_SMS_ITEM_FAILURE:null,

    LOAD_SMS_ITEMS_REQUEST:null,
    LOAD_SMS_ITEMS_SUCCESS:null,
    LOAD_SMS_ITEMS_FAILURE:null,

    LOAD_DIALOGS_REQUEST:null,
    LOAD_DIALOGS_SUCCESS:null,
    LOAD_DIALOGS_FAILURE:null,

    DETAIL_TAPPED:null,
    CONTACTS_TAPPED:null,
    GCPS_TAPPED:null,
    PRODUCTS_TAPPED:null,
    SIDEMENU_CLICKED:null,
    SIDEMENU_COMPANY_CLICKED:null,

    MAIN_CAT_INPUT_TAPPED:null,
    SUB_CAT_INPUT_TAPPED:null,
    UNIT_CAT_INPUT_TAPPED:null,
    RESET_CAT_INPUT_TAPPED:null,

    RESET_ERRORS:null,

    LOAD_SMS_MASS_BODY_REQUEST:null,
    LOAD_SMS_MASS_BODY_SUCCESS:null,
    LOAD_SMS_MASS_BODY_FAILURE:null,

    LOAD_SMS_MASS_BODIES_REQUEST:null,
    LOAD_SMS_MASS_BODIES_SUCCESS:null,
    LOAD_SMS_MASS_BODIES_FAILURE:null,

    LOAD_SMS_MASS_ITEMS_REQUEST:null,
    LOAD_SMS_MASS_ITEMS_SUCCESS:null,
    LOAD_SMS_MASS_ITEMS_FAILURE:null,

    GET_SMS_MASS_ITEMS_CHECKED_COUNT_REQUEST:null,
    GET_SMS_MASS_ITEMS_CHECKED_COUNT_SUCCESS:null,
    GET_SMS_MASS_ITEMS_CHECKED_COUNT_FAILURE:null,

});


export const Pages =  keyMirror({
    HOMELIST: null,
    GROUPS: null,
    MESSAGES: null,
    PROFILE: null,
    READING: null,
    BILLING: null,
});

export const FB_APP_ID = '843444899138235';


//const BASE_WS_URL = 'ws://192.168.1.121:9000/';

// const BASE_URL= 'http://192.168.1.121:9000/';
// const BASE_WS_URL = 'ws://192.168.1.121:9000/';

export const TOKEN = "id_token"
export const GLN = "GLN"
export const GTIN = "GTIN"
export const SSCC = "SSCC"

export const Page  =  {
    PAGE:"pg",
    PAGESIZE:"ps",
    ASC:"asc",
    ORDER:"ord"
}

export const Menu  =  {
    DASHBOARD:"DASHBOARD",
    COMPANIES_ACTIVE:"COMPANIES_ACTIVE",
    COMPANIES_REMOVED:"COMPANIES_REMOVED",
    COMPANIES_TEMP_CLOSED:"COMPANIES_TEMP_CLOSED",
    GCPS:"GCPS",
    HQGCPS:"HQGCPS",
    HQBATCH:"HQBATCH",
    PRODUCTS:"PRODUCTS",
    PRODUCT8S:"PRODUCTS8",
    PRODUCTS14:"PRODUCTS14",
    COMPANY_APPLS:"COMPANY_APPLS",
    PROD_APPLS:"PROD_APPLS",
    INVOICES:"INVOICES",
    MAILS:"MAILS",
    SMS:"SMS",
    GS1_8:"GS1_8",
    GS1_14:"GS1_14",
    SETTINGS:"SETTINGS",
    PROFILE:"PROFILE"
};

export const PageTitle  =  {
    DASHBOARD:"Dashboard",
    COMPANIES_ACTIVE:"Байгууллагууд",
    COMPANIES_REMOVED:"Хасагдсан",
    COMPANIES_TEMP_CLOSED:"Түр Хаагдсан",
    GCPS:"Gcps",
    HQ_GCPS:"HQ Gcps",
    HQ_BATCH:"HQ Check",
    PRODUCTS:"Бүтээгдэхүүнүүд",
    COMPANY_APPLS:"Шинэ Байгууллага",
    PROD_APPLS:"Шинэ Бүтээгдэхүүн",
    INVOICES:"Нэхэмжлэхүүд",
    GS1_8:"GS1-8",
    GS1_14:"GS1-14",
    SETTINGS:"Тохиргоо",
    PROFILE:"Профайл"
};

export const Endpoints  =  {
    GET_FEED:BASE_URL + 'api/mfeed/list.json',
    GET_USERS_URL:BASE_URL + 'api/users/list.json',
    GET_INTERESTS_URL:BASE_URL + 'api/intr/list.json',
    GET_INTEREST_URL:BASE_URL + 'api/intr/get.json',
    CREATE_AD_TXT_URL:BASE_URL + 'api/feed/crtText.json',
    START_RTM_URL:BASE_URL + 'start.json',
    SELECT_COMMUNITY_URL:BASE_URL + 'select_community.json',
    REGISTER:BASE_URL + 'v1/register',
    INVITE:BASE_URL + 'v1/invite',
    INSTANT_INVITE:BASE_URL + 'v1/instant_invite',
    SIGNUP_INVITE_URL:BASE_URL + 'v1/signup_invite',
    AUTH_SIGNIN_URL:BASE_URL + 'authenticate',
    LOGIN_URL:BASE_URL + 'api/login',
    LOGINACC_URL:BASE_URL + 'api/loginacc',
    LOGOUT_URL:BASE_URL + 'logout',
    SIGNUP_URL:BASE_URL + 'signup',
    VERIFY:BASE_URL + 'verify_token',
    FORGOT_PASSWORD:BASE_URL + 'forgot',
    RESET_PASSWORD:BASE_URL + 'reset',

    ORGS:BASE_URL + 'admin/orgs',
    GROUPS:BASE_URL + 'api/tchat/groups',
    ORG_AGENTS:BASE_URL + 'admin/org-agents',
    AGENTS:BASE_URL + 'admin/agents',
    AGENT_HOUSES:BASE_URL + 'admin/agent-houses',

    DASHBOARD:BASE_URL + 'v1.0/dashboard',
    COMPANIES:BASE_URL + 'v1.0/companies',
    GCPS:BASE_URL + 'v1.0/gcps',
    COMPANY:BASE_URL + 'v1.0/company',
    CONTACT:BASE_URL + 'v1.0/contact',
    COMPANY_REQ_SELF:BASE_URL + 'v1.0/company-self-req',

    USER:BASE_URL + 'v1.0/user',
    INVOICE_MAILS:BASE_URL + 'v1.0/invoice-mails',
    INVOICE_MAIL_ITEMS:BASE_URL + 'v1.0/invoice-mail-items',
    INVOICE_MAIL_TEMPLATE:BASE_URL + 'v1.0/invoice-mail-template',
    INVOICE_MAIL_TEMPLATES:BASE_URL + 'v1.0/invoice-mail-templates',
    SEND_INVOICE_MAILS:BASE_URL + 'v1.0/send-invoice-mails',

    SECTORS:BASE_URL + 'v1.0/sectors',
    SECTOR:BASE_URL + 'v1.0/sector',

    FEES:BASE_URL + 'v1.0/fees',

    CLASSIF:BASE_URL + 'v1.0/classif',

    GCP:BASE_URL + 'v1.0/gcp',
    PRODUCTS:BASE_URL + 'v1.0/products',
    PRODUCT8S:BASE_URL + 'v1.0/product8s',
    PRODUCT:BASE_URL + 'v1.0/product',
    PRODUCT8:BASE_URL + 'v1.0/product8',
    PRODUCT8_FILE:BASE_URL + 'v1.0/product8-file',
    BARCODE:BASE_URL + 'v1.0/barcode',
    NOTE:BASE_URL + 'v1.0/note',

    COMPANY_REQUESTS:BASE_URL + 'v1.0/company-reqs',
    COMPANY_REQUEST:BASE_URL + 'v1.0/company-req',

    PRODUCT_REQUESTS:BASE_URL + 'v1.0/product-reqs',
    PRODUCT_REQUEST:BASE_URL + 'v1.0/product-req',


    INVOICES:BASE_URL + 'v1.0/invoices',
    INVOICE:BASE_URL + 'v1.0/invoice',

    DISTRICTS:BASE_URL + 'api/houses/districts',


    CHANNELS:BASE_URL + 'api/chat',
    MESSAGES:BASE_URL + 'api/chat/room',

    SMS_REQS:BASE_URL + 'api/sms-reqs',
    DEF_POSTS:BASE_URL + 'api/posts-deferred',
    DEF_POST:BASE_URL + 'api/post-deferred',

    ORG_PHONES:BASE_URL + 'admin/org-phones',
    APPT_PHONES:BASE_URL + 'api/appt-phones',

    APPT_CREATE:BASE_URL + 'api/appts/tcreate',

    ACCOUNTKIT_LOGIN:'https://www.accountkit.com/v1.0/basic/dialog/sms_login/',
    ACCOUNTKIT_REDIRECT:BASE_URL + 'api/loginacc',
    //ACCOUNTKIT_REDIRECT_BASIC:ACC_KIT_BASE_URL + 'acclogin',
    CHECK_USER:BASE_URL + 'api/check-user',
    UPLOAD_FILE:BASE_URL + 'api/upload',
    DOWNLOAD_FILE:BASE_URL + 'api/download',
    DOC_REQUESTS:BASE_URL + 'api/doc-requests',
    NOTIFICATIONS:BASE_URL + 'api/notifications',
    NOTIFICATION_ORDER:BASE_URL + 'api/notif-order',
    NOTIFICATION_ORDERS:BASE_URL + 'api/notif-orders',
    ME:BASE_URL + 'api/me',

    V1_2_POST:BASE_URL + 'v1.2/post',
    V1_2_POSTS:BASE_URL + 'v1.2/posts',
    V1_2_COMMENT:BASE_URL + 'v1.2/comment',

    V1_2_CHAT:BASE_URL + 'v1.2/chat',
    V1_2_APPT:BASE_URL + 'v1.2/appt',

}

export const EndpointsExpress  =  {
    LOGIN:BASE_EXPRESS_URL + 'api/login',
    CHECK:BASE_EXPRESS_URL + 'api/login/check',
    LOGOUT:BASE_EXPRESS_URL + 'api/logout',
    COMPANY:BASE_EXPRESS_URL + 'v1.0/company',
    COMPANY_ADMIN:BASE_EXPRESS_URL + 'v1.0/company-admin',
    CONTACT:BASE_EXPRESS_URL + 'v1.0/contact',
    GCP:BASE_EXPRESS_URL + 'v1.0/gcp',
    NOTE:BASE_EXPRESS_URL + 'v1.0/note',
    INVOICE_MAIL_TEMPLATE_LINK:BASE_EXPRESS_URL + 'v1.0/invoice-mail-template-link',
    INVOICES_ADMIN:BASE_EXPRESS_URL + 'v1.0/invoices-admin',
    INVOICE_ADMIN:BASE_EXPRESS_URL + 'v1.0/invoice-admin',
    FEES:BASE_EXPRESS_URL + 'v1.0/fees',
    PACKAGING_UNITS:BASE_EXPRESS_URL + 'v1.0/packaging-units',
    COMPANIES:BASE_EXPRESS_URL + 'v1.0/companies',
    PRODUCT:BASE_EXPRESS_URL + 'v1.0/product',
    PRODUCT_REQUESTS:BASE_EXPRESS_URL + 'v1.0/product-reqs/all',
    PRODUCT_REQUEST:BASE_EXPRESS_URL + 'v1.0/product-req-admin',
    PRODUCT_ADMIN:BASE_EXPRESS_URL + 'v1.0/product-admin',
    PRODUCTS_ADMIN:BASE_EXPRESS_URL + 'v1.0/products-admin',
    PRODUCT8S_ADMIN:BASE_EXPRESS_URL + 'v1.0/product8s-admin',
    PRODUCT8_ADMIN:BASE_EXPRESS_URL + 'v1.0/product8-admin',
    NOTES:BASE_EXPRESS_URL + 'v1.0/notes',

    PRODUCT_IMAGE:BASE_EXPRESS_URL + 'v1.0/product-image',
    PRODUCT_IMAGE_ADMIN:BASE_EXPRESS_URL + 'v1.0/product-image-admin',
    PRODUCT_IMAGE_REQUESTS:BASE_EXPRESS_URL + 'v1.0/product-images-admin',

    INVOICE_MAIL_TEMPLATE:BASE_EXPRESS_URL + 'v1.0/invoice-mail-template',
    INVOICE_MAIL_TEMPLATES:BASE_EXPRESS_URL + 'v1.0/invoice-mail-templates',
    INVOICE_MAILS:BASE_EXPRESS_URL + 'v1.0/invoice-mails',
    INVOICE_MAIL_ITEMS:BASE_EXPRESS_URL + 'v1.0/invoice-mail-items',

    SMS_TEMPLATE:BASE_EXPRESS_URL + 'v1.0/sms-template',
    SMS_ITEMS:BASE_EXPRESS_URL + 'v1.0/sms-items',
    SMS_ITEM:BASE_EXPRESS_URL + 'v1.0/sms-item',
    SMS_NEW_ITEM:BASE_EXPRESS_URL + 'v1.0/sms-new-item',

    COMPANY_REQUESTS:BASE_EXPRESS_URL + 'v1.0/company-reqs',
    COMPANY_REQUEST:BASE_EXPRESS_URL + 'v1.0/company-req',

    SECTORS:BASE_EXPRESS_URL + 'v1.0/sectors',
    SECTOR:BASE_EXPRESS_URL + 'v1.0/sector',

    CLASSIF:BASE_EXPRESS_URL + 'v1.0/classif',
    GCP_ADMIN:BASE_EXPRESS_URL + 'v1.0/gcp-admin',
    GCPS_ADMIN:BASE_EXPRESS_URL + 'v1.0/gcps-admin',
    HQ_GCPS:BASE_EXPRESS_URL + 'v1.0/hq-gcps',
    HQ_PRODUCTS:BASE_EXPRESS_URL + 'v1.0/hq-products',
    HQ_BATCH:BASE_EXPRESS_URL + 'v1.0/hq-batch',
    HQ_BATCHES:BASE_EXPRESS_URL + 'v1.0/hq-batches',
    COUNTRIES:BASE_EXPRESS_URL + 'v1.0/countries',
    SENT_EMAILS:BASE_EXPRESS_URL + 'v1.0/sent-emails',
    SMS_MASS_BODY:BASE_EXPRESS_URL + 'v1.0/sms-mass-body',
    SMS_MASS_BODIES:BASE_EXPRESS_URL + 'v1.0/sms-mass-bodies',
    SMS_MASS_ITEM:BASE_EXPRESS_URL + 'v1.0/sms-mass-item',
    SMS_MASS_ITEMS:BASE_EXPRESS_URL + 'v1.0/sms-mass-items',
    SIGNUPS:BASE_EXPRESS_URL + 'v1.0/signups',
    AIMAKS:BASE_EXPRESS_URL + 'v1.0/aimaks'
}
